/* bootstrap call css stylesheet */
@import "/assets/app-assets/css/bootstrap.min.css";
@import "/assets/app-assets/css/bootstrap-extended.min.css";

/* Google fonts */
@import url(https://fonts.googleapis.com/css?family=Rubik:300,400,500,600|IBM+Plex+Sans:300,400,500,600,700);

/* import vendor style */
@import "/assets/app-assets/vendors/css/vendors.min.css";

/* import apps style */
@import "/assets/app-assets/css/colors.min.css";
@import "/assets/app-assets/css/components.min.css";
@import "/assets/app-assets/css/themes/dark-layout.min.css";
@import "/assets/app-assets/css/themes/semi-dark-layout.min.css";

/* import layout style */
@import "/assets/app-assets/css/core/menu/menu-types/vertical-menu.min.css";

/* Custom Css */
body.dark-layout .alert {
  margin-bottom: 1rem;
}
body.dark-layout .alert .btn-close {
  position: absolute;
  top: 50%;
  right: 0.5rem;
  color: #ffffff;
  padding: 0;
  width: 45px;
  height: 45px;
  border-radius: 0;
  -webkit-filter: invert(1);
  -moz-filter: invert(1);
  filter: invert(1);
  -webkit-box-shadow: none !important;
  -moz-box-shadow: none !important;
  box-shadow: none !important;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}
body.dark-layout .card-header {
  position: relative;
}
body.dark-layout .card .heading-elements {
  position: absolute;
  top: 50%;
  right: 1.7rem;
  -webkit-transform: translateY(-50%);
  -moz-transform: translateY(-50%);
  transform: translateY(-50%);
}
body.dark-layout .css-1s2u09g-control,
body.dark-layout .css-1pahdxg-control,
body.dark-layout .css-t3ipsp-control,
body.dark-layout .css-1insrsq-control,
body.dark-layout .css-13cymwt-control {
  background-color: #1a233a;
  color: #8a99b5;
  border-color: #464d5c !important;
}
body.dark-layout .css-6j8wv5-Input,
body.dark-layout .css-1pndypt-Input {
  color: #8a99b5;
}
body.dark-layout .css-26l3qy-menu,
body.dark-layout .css-1nmdiq5-menu {
  z-index: 999;
  background-color: #1a233a;
  color: #8a99b5 !important;
  border-color: #464d5c !important;
}
body.dark-layout .css-qc6sy-singleValue,
body.dark-layout .css-1dimb5e-singleValue,
body.dark-layout .css-1dimb5e-singleValue + div.css-19bb58m,
body.dark-layout .css-19bb58m,
body.dark-layout .css-19bb58m input,
body.dark-layout .css-1cfo1cf,
body.dark-layout .css-14el2xx-placeholder {
  color: #8a99b5 !important;
}
body.dark-layout .rdt_Table,
body.dark-layout .rdt_Pagination {
  font-family: "Rubik";
  background-color: transparent;
}
body.dark-layout .rdt_TableRow {
  font-family: "Rubik";
  background-color: transparent;
  color: #8a99b5;
}
body.dark-layout .rdt_TableHeadRow {
  background-color: transparent;
}
body.dark-layout .rdt_TableHead {
  font-size: 0.8rem;
  font-weight: bold;
  color: #8a99b5;
  text-transform: uppercase;
}
body.dark-layout .kJrhuj {
  font-family: "Rubik";
  background-color: transparent;
  color: #8a99b5;
}
body.dark-layout .bvxQGL {
  color: #8a99b5;
  fill: #8a99b5;
}
body.dark-layout .bvxQGL:disabled {
  color: rgba(0, 0, 0, 0.5);
  fill: rgba(0, 0, 0, 0.5);
}
body.dark-layout .dyGtOs {
  background-color: transparent;
}
body.dark-layout .ifOHjV {
  display: block;
}
body.dark-layout .fwKvpK {
  background-color: #1a233a;
  color: #8a99b5;
}
body.dark-layout .main-menu-content {
  height: calc(100vh - 80px) !important;
  overflow-x: hidden;
  overflow-y: auto;
}
body.dark-layout .navigation-header:last-child {
  display: none;
}
body.dark-layout .main-menu.menu-dark .navigation .navigation-header {
  color: #bac0c7;
  margin: calc(2.2rem - 0.5rem) 0 0.5rem 1.8rem;
  padding: 0;
  letter-spacing: 1px;
}
body.dark-layout .main-menu.menu-dark .navigation li {
  margin: 0 1rem;
  -webkit-transition: background-color 0.5s ease;
  transition: background-color 0.5s ease;
}
body.dark-layout .main-menu.menu-dark .navigation li.nav-item:not(.has-sub) a {
  padding: 10px 12px;
}
body.dark-layout.vertical-layout.vertical-menu-modern
  .main-menu
  .navigation
  li
  a
  i {
  font-size: 1.4rem;
  width: 1.6rem !important;
  margin-right: 1rem;
  float: left;
}
body.dark-layout .main-menu-content::-webkit-scrollbar {
  width: 6px;
}
body.dark-layout .main-menu-content::-webkit-scrollbar-track {
  background-color: rgba(209, 215, 222, 0.1);
}
body.dark-layout .main-menu-content::-webkit-scrollbar-thumb {
  background-color: #d1d7de;
  border-radius: 5px;
}
body.dark-layout .main-menu-content::-webkit-scrollbar-thumb:hover {
  background-color: #d1d7de;
}
body.dark-layout .btn.btn-icon {
  display: inline-block;
  text-align: center;
  border-radius: 100%;
  padding: 0;
  width: 36px;
  height: 36px;
  line-height: 40px;
  background: #1a233a;
  color: #8a99b5;
}
body.dark-layout .btn.btn-icon i {
  top: 0;
}
body.dark-layout .btn.btn-icon.btn-action {
  line-height: unset;
}
body.dark-layout .form-check,
body.dark-layout .form-check label {
  cursor: pointer;
}
body.dark-layout .apexcharts-canvas {
  margin: 0 auto;
}
body.dark-layout
  .tui-calendar-react-root
  span:not(.tui-full-calendar-holiday-sun) {
  color: #8a99b5 !important;
}
body.dark-layout .calendar-action .dropdown-item {
  padding: 0.5rem 1.6rem;
}
body.dark-layout .calendar-action .form-check {
  min-height: auto;
  margin-bottom: 0;
}
body.dark-layout .calendar-action .form-check label {
  margin-top: 4px;
  line-height: 1;
}
body.dark-layout .daterangepicker .drp-calendar.right {
  padding-left: 0;
}
body.dark-layout .daterangepicker select.hourselect,
body.dark-layout .daterangepicker select.minuteselect,
body.dark-layout .daterangepicker select.secondselect,
body.dark-layout .daterangepicker select.ampmselect {
  border-color: #272e48;
  background: #272e48;
  color: #bdd1f8;
}
body.dark-layout .daterangepicker th.in-range.available,
body.dark-layout .daterangepicker td.in-range.available {
  color: #8a99b5;
}
body.dark-layout .react-datepicker {
  background-color: #1a233a;
  border-color: rgba(255, 255, 255, 0.1);
}
body.dark-layout .react-datepicker__header {
  background-color: rgba(255, 255, 255, 0.1);
  border-color: rgba(255, 255, 255, 0.1);
}
body.dark-layout .react-datepicker__day-name,
body.dark-layout .react-datepicker__day,
body.dark-layout .react-datepicker__time-name {
  color: #8a99b5;
}
body.dark-layout
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::before,
body.dark-layout
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle::after {
  border-bottom-color: rgba(255, 255, 255, 0.1);
}
body.dark-layout
  .react-datepicker-popper[data-placement^="bottom"]
  .react-datepicker__triangle {
  margin-left: -25px;
}
body.dark-layout .react-datepicker__year .react-datepicker__year-text {
  width: 47.5%;
  height: 30px;
  line-height: 30px;
  border-radius: 3px;
  color: #608cb5;
}
body.dark-layout
  .react-datepicker__year
  .react-datepicker__year-text--keyboard-selected {
  color: #fff;
}
body.dark-layout .react-datepicker__current-month,
body.dark-layout .react-datepicker-time__header,
body.dark-layout .react-datepicker-year-header {
  color: #608cb5;
}
body.dark-layout .form-check.form-switch input.form-check-input {
  margin-left: -1.25rem;
}
body.dark-layout .form-check.form-switch label.form-check-label {
  margin-left: 1.25rem;
}
body.dark-layout .floating-toast {
  position: absolute;
  top: 72px;
  right: 32px;
  z-index: 9999;
}
body.dark-layout .transaction-summary-total {
  position: relative;
  box-sizing: border-box;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 100%;
}
body.dark-layout .transaction-summary-total tbody {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: column;
  -ms-flex-direction: column;
  flex-direction: column;
}
body.dark-layout .transaction-summary-total tr {
  font-family: "Rubik";
  background-color: transparent;
  color: #8a99b5;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: stretch;
  -webkit-box-align: stretch;
  -ms-flex-align: stretch;
  align-items: stretch;
  -webkit-align-content: stretch;
  -ms-flex-line-pack: stretch;
  align-content: stretch;
  width: 100%;
  box-sizing: border-box;
  font-size: 13px;
  font-weight: 400;
  min-height: 48px;
}
body.dark-layout .transaction-summary-total td {
  position: relative;
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-align-items: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  box-sizing: border-box;
  line-height: normal;
  padding-left: 16px;
  padding-right: 16px;
  word-break: break-word;
  -webkit-box-flex: 1;
  -webkit-flex-grow: 1;
  -ms-flex-positive: 1;
  flex-grow: 1;
  -webkit-flex-shrink: 0;
  -ms-flex-negative: 0;
  flex-shrink: 0;
  -webkit-flex-basis: 0;
  -ms-flex-preferred-size: 0;
  flex-basis: 0;
  max-width: 100%;
  min-width: 100px;
  min-width: 33, 333333%;
  max-width: 33, 333333%;
}
body.dark-layout .jKtrke {
  display: block;
}
